import "./OpenplayQueryEditor.less";
import { useOpenplayQueries } from "@/api/openplay-queries/hooks";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Button, Form, Popconfirm, Select } from "antd";
import { OpenplayQueryModal } from "@/components/OnDemandReports/Details/OpenplayQueryList/OpenplayQueryEditor/OpenplayQueryModal";
import { useBoolean } from "ahooks";
import { omit } from "ramda";

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const OpenplayQueryEditor = ({ value, onChange }: Props) => {
  const {
    data: { data: entries },
    isLoading,
  } = useOpenplayQueries();

  const [form] = Form.useForm();

  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const selectedQuery = entries.find((entry) => entry.id === value);

  const handleClose = () => {
    form.resetFields();
    close();
  };

  const handleEdit = () => {
    form.setFieldsValue(selectedQuery);
    open();
  };

  const handleDuplicate = () => {
    form.setFieldsValue(omit(["id"], selectedQuery));
    open();
  };

  return (
    <div className="openplay-query-editor">
      <Select
        placeholder="Select Existing or Create New"
        allowClear
        loading={isLoading}
        disabled={isLoading}
        options={entries.map((entry) => ({ value: entry.id, label: entry.name }))}
        suffixIcon={<Icon component={DropdownArrow} />}
        value={value}
        onChange={onChange}
      />
      {selectedQuery ? (
        <>
          <Popconfirm
            title="Editing existing OpenPlay query may impact other reports that reference it"
            okText="Edit Anyway"
            onConfirm={handleEdit}
            overlayStyle={{ maxWidth: 300 }}
          >
            <Button size="small">Edit</Button>
          </Popconfirm>
          <Button size="small" onClick={handleDuplicate}>
            Duplicate & Edit
          </Button>
        </>
      ) : (
        <Button type="primary" size="small" onClick={open}>
          Create
        </Button>
      )}
      <OpenplayQueryModal open={isOpen} form={form} onClose={handleClose} onFinish={onChange} />
    </div>
  );
};
