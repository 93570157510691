import { api } from "../core";
import { isActive, Status, stripUndefined } from "@/utils";
import type { User, UserFilters } from "@/api/users/types";
import type { PaginatedResponse } from "@/api/types";

const url = "/api/users";

export const fetchUsers = async (params: UserFilters) =>
  api.get<any, PaginatedResponse<User>>(url, { params });

export const getCurrentUser = async () => api.get(`${url}/me`);

export const getUser = async (id: string) => {
  const data = await api.get<any, User>(`${url}/${id}`);

  return {
    ...data,
    isActive: isActive(data.status),
  };
};

const prepareUserData = ({ isActive, ...rest }) => {
  const data = rest;
  if (typeof isActive !== "undefined") {
    data.status = isActive ? Status.Active : Status.Inactive;
  }
  return data;
};

export const createUser = async (data) => api.post(url, prepareUserData(data));

export const updateUser = async (id, data) => api.put(`${url}/${id}`, prepareUserData(data));

export const saveUser = (fields) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields
    ? updateUser(cleanedUpFields.id, cleanedUpFields)
    : createUser(cleanedUpFields);
};

export const findUserByNameAndEmail = async (params: { name?: string; email?: string }) =>
  await api.get<any, User>(`${url}/find`, { params });
