import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { atLeastOne } from "@/utils/validation";
import { Button, Form } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { OpenplayQueryListItem } from "./OpenplayQueryListItem";
import "./OpenplayQueryList.less";

export const OpenplayQueryList = () => {
  return (
    <FieldList name="openplayQueries" rules={[atLeastOne("At least one query should be added")]}>
      {(fields, { add, remove }, { errors }) => (
        <div className="openplay-query-list">
          <Button
            className="openplay-query-list__add"
            size="small"
            icon={<Icon component={Plus} />}
            onClick={() => add({ id: void 0 })}
          >
            Add Query
          </Button>
          {fields.map((field) => (
            <FieldListItem key={field.key} name={field.name}>
              <OpenplayQueryListItem fieldIndex={field.name} onRemove={() => remove(field.name)} />
            </FieldListItem>
          ))}
          {errors.length > 0 && <Form.ErrorList errors={errors} />}
        </div>
      )}
    </FieldList>
  );
};
