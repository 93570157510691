import { MappingTemplateForm } from "./MappingTemplateForm";
import { Button, Col, Divider, Form, Input, PageHeader, Popconfirm, Row, Select, Space } from "antd";
import { TemplateType, useAuth, useIsAllowedForRoles, UserRole } from "@/utils";
import { FiltersTemplateForm } from "./FiltersTemplateForm";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { TemplateTypeSelector } from "./TemplateTypeSelector";
import { useHistory } from "react-router-dom";
import { AssetNamingTemplateForm } from "./AssetNamingTemplateForm";
import { Box } from "../../common/Box/Box";
import { FormActions } from "../../common/FormActions";
import { TargetObjectTag } from "../../common/TargetObjectTag/TargetObjectTag";
import { OperationTag } from "../../common/OperationTag/OperationTag";
import { useBoolean } from "ahooks";
import { deleteTemplate } from "@/api/templates";
import { SalesforceQueryForm } from "../../common/SalesforceQuery/SalesforceQueryForm";
import { isUniqueTemplateName, parseTemplateFields, prepareTemplateDto } from "./utils";
import { Tooltip } from "@/components/common/Tooltip";
import { SpreadsheetColumnList } from "app/components/Spreadsheets/SpreadsheetColumnList";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import { ColumnSetDataSource } from "@/api/spreadsheets/types";
import { getDataSourceLabel } from "@/components/Spreadsheets/ColumnSets/utils";
import { useTemplateMutation } from "@/api/templates/hooks";

export const TemplateForm = ({ initialValues = {}, isEditingByDefault }) => {
  const [form] = Form.useForm();

  const type = Form.useWatch("type", form);
  const operation = Form.useWatch("operation", form);
  const targetObject = Form.useWatch("targetObject", form);
  const sourceObject = Form.useWatch("sourceObject", form);
  const source = Form.useWatch("source", form);

  const { preventNavigation, allowNavigation } = useNavigationConfirm();
  const history = useHistory();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const handleSelectTemplate = (values) => {
    form.setFieldValue("type", values.type);
    form.setFieldValue("operation", values.operation);
    form.setFieldValue("targetObject", values.targetObject);

    if (values.type === TemplateType.Mapping) {
      form.setFieldValue(["mapping", "inputFormat"], values.inputFormat);
      form.setFieldValue(["mapping", "outputFormat"], values.outputFormat);
    }

    if (values.type === TemplateType.AssetNaming) {
      form.setFieldValue("assetType", values.assetType);
    }

    if (values.type === TemplateType.SpreadsheetColumnSet) {
      form.setFieldValue("sourceObject", values.sourceObject);
      form.setFieldValue("source", values.source);
    }
  };

  const title = initialValues.name ?? "New Template";

  const isDeletable = !!initialValues.id && !editing;

  const handleDelete = async () => {
    if (initialValues.id) {
      await deleteTemplate(initialValues.id);
      history.push(`/templates`);
    }
  };

  const isAdmin = useIsAllowedForRoles(UserRole.Admin);
  const { user } = useAuth();
  const canEdit = isAdmin || user.userId === initialValues.updatedById;

  const handleCancel = () => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(parseTemplateFields(initialValues));
      disableEditing();
    } else {
      history.push(`/templates`);
    }
  };

  const { isMutating: saving, trigger: saveTemplate } = useTemplateMutation(initialValues.id);

  const handleSave = async (dto) => {
    try {
      const { id } = await saveTemplate(dto);
      if (!initialValues.id) {
        history.push(`/templates/${id}`);
        return;
      }
      allowNavigation();
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleFinish = async (values) => {
    const dto = prepareTemplateDto(values);
    try {
      await handleSave(dto);
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  return (
    <>
      <PageHeader
        title={
          <Space align="center" size={12}>
            <span>{title}</span>
            {targetObject && <TargetObjectTag targetObject={targetObject} />}
            {operation && <OperationTag operation={operation} showLabel />}
          </Space>
        }
        extra={
          <>
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
              canEdit={canEdit}
              canSave={!!type}
              cannotEditMessage="Template created by another user can't be edited"
            />
            {isDeletable && (
              <Popconfirm
                title={`Are you sure to delete the ${initialValues?.name} template?`}
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canEdit}
              >
                <Tooltip title={canEdit ? null : "Template created by another user can't be deleted"}>
                  <Button disabled={!canEdit}>Delete</Button>
                </Tooltip>
              </Popconfirm>
            )}
          </>
        }
      />
      <Form
        form={form}
        colon={false}
        onValuesChange={preventNavigation}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        onFinish={handleFinish}
        initialValues={parseTemplateFields(initialValues)}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="type" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="operation" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="targetObject" noStyle>
          <Input type="hidden" />
        </Form.Item>

        {!type && <TemplateTypeSelector onSelect={handleSelectTemplate} />}
        {type && (
          <Box readOnly={!editing}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Template Name"
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[{ required: true, message: "Field is mandatory." }, isUniqueTemplateName]}
                >
                  <Input placeholder="Template Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="description" label="Description">
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>
            </Row>
          </Box>
        )}
        <Divider />
        {type === TemplateType.Mapping && <MappingTemplateForm disabled={!editing} />}
        {type === TemplateType.Filters && <FiltersTemplateForm disabled={!editing} />}
        {type === TemplateType.AssetNaming && <AssetNamingTemplateForm disabled={!editing} />}
        {type === TemplateType.SalesforceQuery && (
          <FieldGroup prefix="query">
            <Box readOnly={!editing}>
              <SalesforceQueryForm />
            </Box>
          </FieldGroup>
        )}
        {type === TemplateType.SpreadsheetColumnSet && (
          <Box readOnly={!editing}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="source" label="Source">
                  <Select
                    options={toOptions(Object.values(ColumnSetDataSource), getDataSourceLabel)}
                    suffixIcon={<Icon component={DropdownArrow} />}
                    disabled
                  />
                </Form.Item>
              </Col>
              {!!sourceObject && (
                <Col span={12}>
                  <Form.Item name="sourceObject" label="Source Object">
                    <SalesforceObjectPicker disabled />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider orientation="left">Columns</Divider>
            <SpreadsheetColumnList showTemplateActions={false} sourceObject={sourceObject} source={source} />
          </Box>
        )}
      </Form>
    </>
  );
};
