import { Select } from "antd";
import { ColumnSetDataSource, ColumnType } from "@/api/spreadsheets/types";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { toOptions } from "@/utils/toOptions";

type Props = {
  value?: ColumnType;
  onChange?: (value: ColumnType) => void;
  source: ColumnSetDataSource;
};

const columnTypeLabelLookup = {
  [ColumnType.Field]: "Field",
  [ColumnType.StaticValue]: "Static Value",
  [ColumnType.Variable]: "Report Variable",
  [ColumnType.Calculated]: "Calculated Value",
};

export const ColumnTypePicker = ({ value, onChange, source }: Props) => {
  const options = toOptions(
    Object.values(ColumnType).filter((type) =>
      source === ColumnSetDataSource.Salesforce ? type !== ColumnType.Calculated : true
    ),
    (type) => columnTypeLabelLookup[type]
  );
  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
