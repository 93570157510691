import type { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { useFormErrorsState } from "@/components/common/Form/hooks";

type FormSubmitParams<T extends { id?: string | number }> = {
  form: FormInstance<T>;
  onSave: (values: T) => Promise<{ id: string | number }>;
  onDelete?: (id: string | number) => Promise<void>;
  listingUrl: string;
  editUrl: (id: string | number) => string;
  initialValues: T;
  isEditingByDefault?: boolean;
};

export const useFormActions = <T extends { id?: string | number }>({
  onSave,
  onDelete,
  listingUrl,
  editUrl,
  form,
  initialValues,
  isEditingByDefault = false,
}: FormSubmitParams<T>) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();
  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);
  const { fieldsWithErrors, handleFieldsChange, resetErrors } = useFormErrorsState();

  const redirectToListing = async () => {
    if (listingUrl) {
      await history.push(listingUrl);
    }
  };

  const reset = useCallback(() => {
    // @ts-expect-error
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleSubmit = () => {
    setSubmitting(true);
    form.submit();
  };

  const handleFinish = async (values: T) => {
    try {
      const { id } = await onSave(values);
      if (!initialValues.id) {
        history.push(editUrl(id));
        return;
      }
      allowNavigation();
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFinishFailed = () => {
    setSubmitting(false);
  };

  const handleCancel = async () => {
    allowNavigation();
    if (initialValues.id) {
      reset();
      disableEditing();
      resetErrors();
    } else {
      await redirectToListing();
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const handleDelete = async () => {
    if (initialValues.id) {
      await onDelete?.(initialValues.id);
      await redirectToListing();
    }
  };

  return {
    submitting,
    editing,
    handleSubmit,
    handleFinish,
    handleFinishFailed,
    handleCancel,
    handleValueChange,
    handleEdit,
    handleDelete,
    fieldsWithErrors,
    handleFieldsChange,
  };
};
