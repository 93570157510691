import type { FieldType } from "@/types/salesforce";
import type { OpenplayObjectType } from "@/types/common";
import type { Pagination } from "@/api/types";
import type { SalesforceObject } from "@/api/salesforce-objects/types";

export enum ColumnType {
  Field = "field",
  StaticValue = "static",
  Variable = "variable",
  Calculated = "calculated",
}

interface PlainColumn {
  dataType?: Exclude<FieldType, "date" | "datetime">;
}

type DateColumn = {
  dataType: "date";
  dateFormat?: string;
};

type DatetimeColumn = {
  dataType: "datetime";
  dateFormat?: string;
  timezone?: string;
};

type ColumnWithDatatype = PlainColumn | DateColumn | DatetimeColumn;

type ColumnBase = ColumnWithDatatype & {
  name: string;
  width?: number;
};

export type FieldColumn = ColumnBase & {
  type: ColumnType.Field;
  path: string[];
};

export type StaticValueColumn = ColumnBase & {
  type: ColumnType.StaticValue;
  value: string;
};

export type VariableColumn = ColumnBase & {
  type: ColumnType.Variable;
  variable: string;
};

export type SpreadsheetColumn = FieldColumn | StaticValueColumn | VariableColumn;

export type FormattingRule = {
  condition: Record<string, unknown>;
  background: string;
  color: string;
};

export enum ColumnSetDataSource {
  Salesforce = "salesforce",
  OpenPlayAPI = "openplay",
  OpenPlayQuery = "openplay-query",
}

export type SpreadsheetColumnSet = {
  id: string;
  name: string;
  sourceObject?: SalesforceObject;
  source: ColumnSetDataSource;
  columns: SpreadsheetColumn[];
};

export type CreateSpreadsheetColumnSetDto = Omit<SpreadsheetColumnSet, "id">;
export type UpdateSpreadsheetColumnSetDto = Partial<CreateSpreadsheetColumnSetDto> &
  Pick<SpreadsheetColumnSet, "id">;

export type SpreadsheetColumnSetFilters = {
  source?: ColumnSetDataSource;
  sourceObject?: string;
} & Pagination;

export type SpreadsheetTab = {
  name: string;
  query: string;
  source: ColumnSetDataSource;
  columnSet: SpreadsheetColumnSet;
  rules: FormattingRule[];
};

export interface Spreadsheet {
  id: string;
  filename?: string;
  tabs: SpreadsheetTab[];
}

export type CalculatedFieldInfo = {
  key: string;
  label: string;
  entity: OpenplayObjectType;
  type: "string" | "number" | "boolean";
};

export type SpreadsheetTemplate = {
  key: string;
  label: string;
  sourceObject: string;
};
