import { Box } from "@/components/common/Box/Box";
import { Button, Col, Form, Row, Select } from "antd";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { getDataSourceLabel } from "@/components/Spreadsheets/ColumnSets/utils";
import { ColumnSetDataSource } from "@/api/spreadsheets/types";

type FormValues = { sourceObject?: string; source: ColumnSetDataSource };

type Props = {
  onSelect: (values: FormValues) => void;
};

export const ColumnSetTypeSelector = ({ onSelect }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const source = Form.useWatch<ColumnSetDataSource>(["source"], form);

  const handleSourceChange = () => {
    form.setFieldValue("sourceObject", undefined);
  };

  const handleFinish = (values: FormValues) => {
    onSelect({
      sourceObject: values.sourceObject ?? null,
      source: values.source,
    });
  };

  return (
    <Box>
      <Form
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        form={form}
        onFinish={handleFinish}
        component="div"
        validateMessages={{ required: "Field is mandatory" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Source"
              rules={[{ required: true }]}
              name="source"
              initialValue={ColumnSetDataSource.Salesforce}
            >
              <Select
                options={toOptions(Object.values(ColumnSetDataSource), getDataSourceLabel)}
                suffixIcon={<Icon component={DropdownArrow} />}
                onChange={handleSourceChange}
              />
            </Form.Item>
          </Col>
          {source !== ColumnSetDataSource.OpenPlayQuery && (
            <Col span={12}>
              <Form.Item label="Source Object" rules={[{ required: true }]} name="sourceObject">
                <SalesforceObjectPicker
                  filter={(obj) =>
                    source === ColumnSetDataSource.OpenPlayAPI ? !!getOpenPlayEquivalent(obj.name) : true
                  }
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Button onClick={form.submit} className="ant-btn-primary">
              Create Column Set
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
};
