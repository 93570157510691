import { Field } from "@/components/common/Form/Field";
import { Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { OpenplayQueryEditor } from "../OpenplayQueryEditor";
import "./OpenplayQueryListItem.less";

type Props = {
  fieldIndex: number;
  onRemove: () => void;
};

export const OpenplayQueryListItem = ({ fieldIndex, onRemove }: Props) => {
  return (
    <div className="openplay-query-list-item">
      <Field
        className="openplay-query-list-item__field"
        name="id"
        rules={[
          { required: true, message: "Please, select a query" },
          (form) => ({
            validator: (_, value) => {
              const idsInUse = (form.getFieldValue(["openplayQueries"]) as { id: string }[])
                .filter((_, index) => fieldIndex !== index)
                .map((query) => query.id);
              return idsInUse.includes(value)
                ? Promise.reject("The query is already in use")
                : Promise.resolve();
            },
          }),
        ]}
      >
        <OpenplayQueryEditor />
      </Field>
      <IconButton icon={Remove} onClick={onRemove} />
    </div>
  );
};
