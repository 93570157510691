import { useFieldPath } from "@/components/common/Form/hooks";
import { Form, Select } from "antd";
import { Field } from "@/components/common/Form/Field";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { getTabSourceLabel, TabSource } from "@/utils/tab-source";
import type { SpreadsheetColumn } from "@/api/spreadsheets/types";

export const TabSourceField = () => {
  const { getAbsolutePath } = useFieldPath();
  const columns = Form.useWatch<SpreadsheetColumn[]>(getAbsolutePath(["columnSet", "columns"])) ?? [];
  const isDisabled = columns.length > 0;
  return (
    <Field
      tooltip={isDisabled ? "Tab source cannot be changed if one or more columns are added" : null}
      name="source"
      label="Source"
      validateFirst
      initialValue={TabSource.Salesforce}
    >
      <Select
        options={toOptions([TabSource.Salesforce, TabSource.OpenPlay], getTabSourceLabel)}
        disabled={isDisabled}
        suffixIcon={<Icon component={DropdownArrow} />}
      />
    </Field>
  );
};
